import React from "react";
import { Textarea, Radio } from "../..";

const SecondStep = ({ register, errors, step, setStep }) => {
  return (
    <div
      className="step_2 stepWrapper"
      style={{ display: step === 2 ? "block" : "none" }}
    >
      <div className="row brief_row">
        <div className="col-md-5">
          <h4>Profil firmy</h4>
          <p>
            Opisz w kilku słowach profil swojej firmy, obszar działania,
            oferowane towary/usługi, na czym polega Twój biznes.
          </p>
        </div>
        <div className="col-md-6 offset-md-1">
          <Textarea
            cols="40"
            rows="3"
            name="company_profile"
            label="Profil firmy"
            register={register}
            required={true}
            errors={errors}
          />
        </div>
      </div>
      <div className="row brief_row">
        <div className="col-md-5">
          <h4>Grupa docelowa</h4>
          <p>Do kogo kierujesz swoją markę/swój produkt?</p>
        </div>
        <div className="col-md-6 offset-md-1">
          <Textarea
            cols="40"
            rows="3"
            name="target_group"
            label="Grupa docelowa"
            register={register}
            required={true}
            errors={errors}
          />
        </div>
      </div>
      <div className="row brief_row">
        <div className="col-md-5">
          <h4>Otoczenie konkurencyjne</h4>
          <p>
            Prosimy o podanie linków do stron www konkurencji, zarówno tej
            najbliższej, osiągalnej, jak i wiodącej na rynku.
          </p>
        </div>
        <div className="col-md-6 offset-md-1">
          <Textarea
            cols="40"
            rows="3"
            name="competition"
            label="Otoczenie konkurencyjne"
            register={register}
            required={true}
            errors={errors}
          />
        </div>
      </div>
      <div className="row brief_row">
        <div className="col-md-5">
          <h4>USP</h4>
          <p>Co wyróżnia Twój produkt spośród konkurencji?</p>
        </div>
        <div className="col-md-6 offset-md-1">
          <Textarea
            cols="40"
            rows="3"
            name="unique_features"
            label="Co wyróżnia Twój produkt spośród konkurencji?."
            register={register}
            required={true}
            errors={errors}
          />
        </div>
      </div>
      <div className="row brief_row">
        <div className="col-md-5">
          <h4>Kanały komunikacji</h4>
          <p>
            Wskaż kanały, za pomocą których komunikujesz się z potencjalnymi
            klientami
          </p>
        </div>
        <div className="col-md-6 offset-md-1">
          <Textarea
            cols="40"
            rows="3"
            name="comunication"
            label="Kanały komunikacji"
            register={register}
            required={true}
            errors={errors}
          />
        </div>
      </div>
      <div className="row brief_row">
        <div className="col-md-5">
          <h4>Ekspansja na rynki zagraniczne</h4>
          <p>
            Czy prowadzisz sprzedaz swoich produktów na rynkach zagranicznych.{" "}
            <br />
            Czy w najbliższym czasie planujesz ekspansję na inne rynki?
          </p>
        </div>
        <div className="col-md-6 offset-md-1">
          <Radio
            name="expansion"
            label="Tak"
            id="expansion_yes"
            register={register}
            errors={null}
          />
          <Radio
            name="expansion"
            label="Nie"
            id="expansion_no"
            register={register}
            errors={null}
          />
          <Radio
            name="expansion"
            label="Nie, ale planuję"
            id="expansion_maybe"
            register={register}
            errors={null}
          />
        </div>
      </div>
      <div className="row brief_row">
        <div className="col-md-5">
          <h4>Sezonowość</h4>
          <p>
            Czy sprzedaż produktów lub usług oferowanych przez Ciebie jest
            sezonowa? <br />
            Jeżeli tak, opowiedz nam więcej o sezonowości Twoich produktów
          </p>
        </div>
        <div className="col-md-6 offset-md-1">
          <Textarea
            cols="40"
            rows="3"
            name="seasons"
            label="Sezonowość"
            register={register}
            required={true}
            errors={errors}
          />
        </div>
      </div>
      <div className="btn-brief-group">
        <button
          type="button"
          className="btn btn-box"
          onClick={() => setStep(1)}
        >
          poprzedni <strong>krok</strong>
        </button>
        <button
          className="btn btn-box"
          type="button"
          onClick={() => setStep(3)}
        >
          kolejny <strong>krok</strong>
        </button>
      </div>
    </div>
  );
};

export default SecondStep;
