import React, { useState } from "react";
import { Input } from "../..";

import $ from "jquery";

const selectColorChange = (e) => {
	e.preventDefault();
	let current = $("#mentor").val();
	if (current !== "Brak") {
		$("#mentor").css("color", "black");
	} else {
		$("#mentor").css("color", "#c7c7c7");
	}
};

const FirstStep = ({
	register,
	errors,
	step,
	setStep,
	triggerValidation,
	formState,
}) => {
	const [isValidating, setValidating] = useState(false);
	const checkErrors = (data) => {
		setTimeout(() => {
			data[Object.keys(data)[0]].ref.focus();
			setValidating(false);
		}, 200);
	};
	isValidating && checkErrors(errors);
	return (
		<div
			className="step_1 stepWrapper"
			style={{ display: step === 1 ? "block" : "none" }}
		>
			{/* eslint-disable jsx-a11y/no-onchange */}
			<p className="desc">
				Zapraszamy do wypełnienia niezbędnego briefu, który służy
				określeniu potrzeb strony i pozwala na przeprowadzenie wstępnej
				wyceny. Cały proces nie powinien zająć więcej niż 15 minut!
			</p>
			<div className="row brief_row justify-content-md-center">
				<div className="col-lg-8">
					<h4 className="align-center">Dane firmy</h4>
					<div className="row">
						<div className="col-sm-6">
							<Input
								type="text"
								name="company_name"
								label="Pełna nazwa firmy"
								register={register}
								validation={{
									required: "Wymagane",
								}}
								errors={errors}
							/>
						</div>
						<div className="col-sm-6">
							<Input
								type="text"
								name="client_name"
								label="Imię i nazwisko"
								register={register}
								validation={{
									required: "Wymagane",
								}}
								errors={errors}
							/>
						</div>
						<div className="col-sm-6">
							<Input
								type="text"
								name="client_position"
								label="Stanowisko"
								register={register}
							/>
						</div>
						<div className="col-sm-6">
							<Input
								type="email"
								name="email"
								label="E-mail"
								register={register}
								validation={{
									required: "Wymagane",
									pattern: {
										value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,6}$/i,
										message: "Błędny adres e-mail",
									},
								}}
								errors={errors}
							/>
						</div>
						<div className="col-sm-6">
							<Input
								type="tel"
								name="phone"
								label="Telefon"
								register={register}
								validation={{
									required: "Wymagane",
								}}
								errors={errors}
							/>
						</div>
						<div className="col-sm-6">
							<Input
								type="text"
								name="website"
								label="Adres strony www"
								register={register}
							/>
						</div>
					</div>
				</div>
			</div>
			<div className="row brief_row justify-content-md-center">
				<div className="col-lg-8">
					<div className="row align-items-center mb-40">
						<div className="col-sm-6">
							<h4 className="align-left mentor">
								Opiekun Klienta
							</h4>
						</div>
						<div className="col-sm-6">
							<div className="select_wrapper">
								<select
									ref={register}
									name="client_guardian"
									id="mentor"
									onChange={selectColorChange}
								>
									<option value="Brak">
										Wybierz opiekuna...
									</option>
									<option value="Jeszcze nie wiem">
										Jeszcze nie wiem
									</option>
									<option value="Patrycja Idzińska">
										Patrycja Idzińska
									</option>
									<option value="Łukasz Pach">
										Łukasz Pach
									</option>
									<option value="Aleksandra Szymańska">
										Aleksandra Szymańska
									</option>
									<option value="Magdalena Jarzębowska">
										Magdalena Jarzębowska
									</option>
									<option value="Alicja Hojcak-Pełczyńska">
										Alicja Hojcak-Pełczyńska
									</option>
									<option value="Anna Waręda">
										Anna Waręda
									</option>
								</select>
							</div>
						</div>
					</div>
				</div>
				<div className="col-md-12 text-right">
					<button
						className="btn btn-box"
						type="button"
						onClick={async () => {
							const isValid = await triggerValidation([
								{ name: "company_name" },
								{ name: "email" },
								{ name: "client_name" },
								{ name: "phone" },
							]);
							isValid ? setStep(2) : setValidating(true);
						}}
					>
						kolejny <strong>krok</strong>
					</button>
				</div>
			</div>
		</div>
	);
};

export default FirstStep;
