import React, { useState } from "react";
import { Input, Check, Textarea } from "../..";
import ShowMore from "react-show-more";

const SubmitStep = ({
	register,
	errors,
	step,
	setStep,
	triggerValidation,
	formState,
}) => {
	const [isLoading, setLoading] = useState(false);

	return (
		<div
			className="step_4 stepWrapper"
			style={{ display: step === 4 ? "block" : "none" }}
		>
			<div className="row brief_row">
				<div className="col-md-5">
					<p>
						Wymień cele biznesowe Twojej firmy (np. Na najbliższy
						rok)
					</p>
				</div>
				<div className="col-md-6 offset-md-1">
					<Textarea
						cols="40"
						rows="3"
						name="business_goals"
						label="Wymień cele biznesowe..."
						register={register}
						required={true}
						errors={errors}
					/>
				</div>
			</div>
			<div className="row brief_row">
				<div className="col-md-5">
					<h4>SUKCES!</h4>
					<p>
						Co będzie dla Ciebie miarą sukcesu? Jaki przychód / zysk
						/ udział budżetu mediowego do przychodu / ilość
						rezerwacji itp. spowodowałaby, że mógłbyś określić naszą
						kampanię jako taką, która osiągnęła sukces?
					</p>
				</div>
				<div className="col-md-6 offset-md-1">
					<Textarea
						cols="40"
						rows="3"
						name="success"
						label="Co chcesz osiągnąć?"
						register={register}
						required={true}
						errors={errors}
					/>
				</div>
			</div>
			<div className="row brief_row">
				<div className="col-md-5">
					<h4>BUDŻET!</h4>
					<p>
						Jakiej kwoty inwestycji nie chcesz przekroczyć w skali
						miesiąca?
					</p>
				</div>
				<div className="col-md-6 offset-md-1">
					<Textarea
						cols="40"
						rows="3"
						name="budget"
						label="Okreś swój budżet.."
						register={register}
						required={true}
						errors={errors}
					/>
				</div>
			</div>
			<div className="row brief_row">
				<div className="col-md-5">
					<h4>START!</h4>
					<p>Kiedy chciałbyś rozpocząć działania reklamowe?</p>
				</div>
				<div className="col-md-6 offset-md-1">
					<Textarea
						cols="40"
						rows="3"
						name="start"
						label="Kiedy chcesz zacząć?"
						register={register}
						required={true}
						errors={errors}
					/>
				</div>
			</div>
			<div className="row brief_row">
				<div className="col-md-5">
					<p>
						Czy twoja firma posiada własny dział graficzny lub
						współpracujesz stale z wybranymi grafikami?
					</p>
				</div>
				<div className="col-md-6 offset-md-1">
					<Textarea
						cols="40"
						rows="3"
						name="graphics"
						label="Dział graficzny"
						register={register}
						required={true}
						errors={errors}
					/>
				</div>
			</div>
			<div className="row brief_row">
				<div className="col-md-5">
					<h4>Kryterium wyboru agencji</h4>
					<p>
						Jakie są kluczowe czynniki decydujące o wyborze agencji?
					</p>
				</div>
				<div className="col-md-6 offset-md-1">
					<Textarea
						cols="40"
						rows="3"
						name="selection_criterion"
						label="Podaj kluczowe czynniki decydujące o wyborze"
						register={register}
						required={true}
						errors={errors}
					/>
				</div>
			</div>
			<div className="row brief_row">
				<div className="col-md-5">
					<h4>Kontakt</h4>
					<p>
						Jaką formę kontaktu preferujesz - telefoniczną / mailową
						/ spotkania osobiste?
					</p>
				</div>
				<div className="col-md-6 offset-md-1">
					<Textarea
						cols="40"
						rows="3"
						name="contact_type"
						label="Preferowana forma kontaktu"
						register={register}
						required={true}
						errors={errors}
					/>
				</div>
			</div>
			<div className="row brief_row">
				<div className="col-md-5">
					<p>W jaki sposób dowiedziałeś się o naszej agencji?</p>
				</div>
				<div className="col-md-6 offset-md-1">
					<Input
						type="text"
						name="get_from"
						label="Reklama w Internecie, znajomi, itd."
						register={register}
						validation={{ required: "Wymagane" }}
						errors={errors}
					/>
				</div>
			</div>
			<Check
				register={register}
				name="term1"
				size="sm"
				validation={{ required: "Wymagane" }}
				errors={errors}
				label={
					<ShowMore
						lines={2}
						more="więcej"
						less="mniej"
						anchorClass="show-more"
					>
						Wyrażam zgodę na przetwarzanie moich danych osobowych
						przez WHEN Sp. z o.o. z siedzibą w Krakowie, ul. Chopina
						18/3a, KRS: 0000683438, REGON: 367571758 (Administrator
						danych) w celu udzielenia mi dodatkowych informacji
						handlowych z zakresu oferty When sp. z o.o. Podanie
						danych jest dobrowolne i umożliwia uzyskanie informacji
						handlowej.
					</ShowMore>
				}
			/>
			<Check
				register={register}
				name="term2"
				size="sm"
				validation={{ required: "Wymagane" }}
				errors={errors}
				label={
					<ShowMore
						lines={2}
						more="więcej"
						less="mniej"
						anchorClass="show-more"
					>
						Wyrażam zgodę na przetwarzanie moich danych osobowych
						przez WHEN Sp. z o.o. z siedzibą w Krakowie, ul. Chopina
						18/3a, KRS: 0000683438, REGON: 367571758 (Administrator
						danych), w celu marketingu bezpośredniego dotyczącego
						własnych produktów i usług.
					</ShowMore>
				}
			/>
			<Check
				register={register}
				name="term3"
				size="sm"
				errors={errors}
				label={
					<ShowMore
						lines={2}
						more="więcej"
						less="mniej"
						anchorClass="show-more"
					>
						Wyrażam zgodę na udostępnienie moich danych osobowych
						przez When sp. z o. o., z siedzibą w Krakowie, ul.
						Chopina 18/3a, KRS: 0000683438 jako administratora moich
						danych osobowych, podmiotowi: Agencja Interaktywna
						ADream z siedzibą w Krakowie, ul. Chopina 18/3a, KRS:
						0000517132 (kolejnemu Administratorowi danych), w celu
						udzielenia mi dodatkowych informacji handlowych z
						zakresu oferty świadczonej przez Agencję Interaktywną
						ADream.
						<br /> Podanie danych jest dobrowolne i umożliwia
						uzyskanie informacji handlowej.*
					</ShowMore>
				}
			/>
			<div className="divider" />
			<ShowMore
				lines={2}
				more="więcej"
				less="mniej"
				anchorClass="show-more"
			>
				Obowiązek informacyjny zgodnie z art. 13 ogólnego rozporządzenia
				o ochronie danych osobowych (RODO) z dnia 27 kwietnia 2016 r.
				(Dz. Urz. UE L 119 z 04.05.2016): informacje o przetwarzaniu
				przez nas danych, jak również o prawach osób, których dane
				dotyczą znajdują się w naszej Polityce Prywatności
			</ShowMore>
			<div className="divider" />
			<div className="btn-brief-group">
				<button
					type="button"
					className="btn btn-box"
					onClick={() => setStep(3)}
				>
					poprzedni <strong>krok</strong>
				</button>
				<button
					type="submit"
					className={`btn btn-box ${
						isLoading === true ? "btn--disabled" : ""
					}`}
					onClick={async () => {
						const isValid = await triggerValidation();
						isValid && setLoading(true);
					}}
				>
					{isLoading ? "wysyłanie..." : "Wyślij wiadomość"}
				</button>
			</div>
		</div>
	);
};

export default SubmitStep;
